import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const ImpresszumPage = () => {
	return (
		<Layout>
			<SEO title="Impresszum" />
			<div className="container px-4">
				<h1 className="pt-12 text-4xl leading-tight font-semibold">Impresszum</h1>
				<p className="text-lg my-8">
					<a className="link" href="mailto:info@mostvasarnap.hu">
						info@mostvasarnap.hu
					</a>{" "}
				</p>
			</div>
		</Layout>
	);
};

export default ImpresszumPage;
